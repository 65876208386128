import { productTypes } from '@nexusmutual/sdk';
import { PRODUCT_TYPES } from 'Constants/coverV2';
import v1Products from 'Constants/v1Products';
import { Product, ProductSDK, ProductType } from 'Features/Membership/ducks/covers/initialState';
import { shortenAddress } from 'Services/utils';

import { get } from './utils';

export const productTypesDisplay = {
  [PRODUCT_TYPES.protocol]: productTypes[PRODUCT_TYPES.protocol].name,
  [PRODUCT_TYPES.custodian]: productTypes[PRODUCT_TYPES.custodian].name,
  [PRODUCT_TYPES.token]: productTypes[PRODUCT_TYPES.token].name,
  [PRODUCT_TYPES.stakewiseEthStaking]: productTypes[PRODUCT_TYPES.stakewiseEthStaking].name,
  [PRODUCT_TYPES.sherlockExcess]: productTypes[PRODUCT_TYPES.sherlockExcess].name,
  [PRODUCT_TYPES.lcEthStaking]: productTypes[PRODUCT_TYPES.lcEthStaking].name,
  [PRODUCT_TYPES.ethSlashing]: productTypes[PRODUCT_TYPES.ethSlashing].name,
  [PRODUCT_TYPES.sherlockQuotaShare]: productTypes[PRODUCT_TYPES.sherlockQuotaShare].name,
  [PRODUCT_TYPES.nativeProtocol]: productTypes[PRODUCT_TYPES.nativeProtocol].name,
  [PRODUCT_TYPES.retailMutual]: productTypes[PRODUCT_TYPES.retailMutual].name,
  [PRODUCT_TYPES.unoreQuotaShare]: productTypes[PRODUCT_TYPES.unoreQuotaShare].name,
  [PRODUCT_TYPES.bundledProtocol]: productTypes[PRODUCT_TYPES.bundledProtocol].name,
  [PRODUCT_TYPES.ethSlashingUmbrella]: productTypes[PRODUCT_TYPES.ethSlashingUmbrella].name,
  [PRODUCT_TYPES.openCoverTransaction]: productTypes[PRODUCT_TYPES.openCoverTransaction].name,
  [PRODUCT_TYPES.fundPortfolio]: productTypes[PRODUCT_TYPES.fundPortfolio].name,
  [PRODUCT_TYPES.sherlockBugBounty]: productTypes[PRODUCT_TYPES.sherlockBugBounty].name,
};

export const getProductType = (address: string): ProductType =>
  PRODUCT_TYPES[get(v1Products, [address, 'type']) as keyof typeof PRODUCT_TYPES];

export const getProductName = (address: string) =>
  get(v1Products, [address, 'name']) || (address && shortenAddress(address, 6)) || 'Unknown';

export const parseProductsArrayToMap = (products: ProductSDK[]): Record<string, Product> => {
  return products.reduce((productsMap: Record<string, Product>, product: ProductSDK) => {
    productsMap[product.id] = {
      ...product,
      productId: product.id,
      type: product.productType,
      deprecated: product.isDeprecated,
    };
    return productsMap;
  }, {});
};
