import React from 'react';
import importedComponent from 'react-imported-component';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { Layout, Loading } from 'Components';
import { MAINTAINANCE } from 'Config';

// Loaded async
const HomePage = importedComponent(
  () => import(/* webpackChunkName:'HomePage' */ 'Features/Membership'),
  {
    LoadingComponent: Loading,
  },
);

const ExchangePage = importedComponent(
  () => import(/* webpackChunkName:'ExchangePage' */ 'Features/BuySell/BuySellPage'),
  {
    LoadingComponent: Loading,
  },
);

const Staking = importedComponent(
  () => import(/* webpackChunkName:'Staking' */ 'Features/Staking'),
  {
    LoadingComponent: Loading,
  },
);

const GovernancePage = importedComponent(
  () => import(/* webpackChunkName:'GovernancePage' */ 'Features/Governance'),
  {
    LoadingComponent: Loading,
  },
);

const CoverPage = importedComponent(
  () => import(/* webpackChunkName:'CoverPage' */ 'Features/Cover'),
  {
    LoadingComponent: Loading,
  },
);

const Assessment = importedComponent(
  () => import(/* webpackChunkName:'Assessment' */ 'Features/Assessment'),
  {
    LoadingComponent: Loading,
  },
);

const Maintainance = importedComponent(
  () => import(/* webpackChunkName:'Maintainance' */ 'Features/Maintainance'),
  {
    LoadingComponent: Loading,
  },
);

const AsyncNoMatch = importedComponent(
  () => import(/* webpackChunkName:'NoMatch' */ 'Components/NoMatch'),
  {
    LoadingComponent: Loading,
  },
);

const hashRoutes: { [key: string]: string } = {
  Dashboard: '/',
  NetworkStatus: '/',
  configuration: '/',
  SmartContractCover: '/cover',
  RiskAssessment: '/staking',
  buyCover: '/cover/buy/select-contract',
  ManageCover: '/cover',
  SubmitProposal: '/governance',
  ViewAll: '/governance',
  ClaimAssessment: '/claims',
  Buy_Redeem: '/nxm',
  kyc: '/membership',
};

const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {MAINTAINANCE ? <Route path="/" component={Maintainance} /> : null}
          {/* Renamed routes */}
          <Redirect from="/exchange" to="/nxm" />
          <Redirect
            from="/cover/proof-of-loss"
            to={{
              pathname: '/membership/proof-of-loss',
              search: window.location.search,
            }}
          />
          <Route
            exact
            path="/"
            render={({ location }) => {
              if (location.hash.includes('#')) {
                return <Redirect to={hashRoutes[location.hash.replace('#/', '')]} />;
              }
              return <Redirect to="/cover" />;
            }}
          />
          <Route path="/membership" component={HomePage} />
          <Route path="/dashboard" component={HomePage} />
          <Route path="/nxm" component={ExchangePage} />
          <Route path="/governance" component={GovernancePage} />
          <Route path="/staking" component={Staking} />
          <Route path="/cover" component={CoverPage} />
          <Route path="/claims" component={Assessment} />
          <Route component={AsyncNoMatch} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};
export default Router;
