import { init } from '@web3-onboard/react';

import fortmaticModule from '@web3-onboard/fortmatic';
import gnosisModule from '@web3-onboard/gnosis';
import injectedModule from '@web3-onboard/injected-wallets';
import keepkeyModule from '@web3-onboard/keepkey';
import ledgerModule from '@web3-onboard/ledger';
import portisModule from '@web3-onboard/portis';
import torusModule from '@web3-onboard/torus';
import trezorModule from '@web3-onboard/trezor';
import walletConnectModule from '@web3-onboard/walletconnect';
import walletLinkModule from '@web3-onboard/walletlink';
import { CHAIN_ID, NETWORK_PROVIDER_HTTP_URL, TENDERLY_NETWORK_PROVIDER_HTTP_URL } from 'Config';

export const initializeConnectionProvider = () => {
  const injected = injectedModule();
  const walletLink = walletLinkModule();
  const rpcUrl = NETWORK_PROVIDER_HTTP_URL + (process.env.REACT_APP_ALCHEMY_API_KEY || '');
  const walletConnect = walletConnectModule({
    projectId: 'c6273e94ad24a8e8647670e10928bf14',
    version: 2,
    dappUrl: 'https://app.nexusmutual.io',
  });

  const portis = portisModule({
    apiKey: 'b2b7586f-2b1e-4c30-a7fb-c2d1533b153b',
  });

  const fortmatic = fortmaticModule({
    apiKey: 'pk_test_886ADCAB855632AA',
  });

  const torus = torusModule();
  const ledger = ledgerModule({
    // for WalletConnect V2
    projectId: 'c6273e94ad24a8e8647670e10928bf14',
  });
  const keepkey = keepkeyModule();

  const trezorOptions = {
    appUrl: 'https://blocknative.com',
    email: '[email protected]',
  };

  const trezor = trezorModule(trezorOptions);
  const gnosis = gnosisModule({
    whitelistedDomains: [
      /^https:\/\/gnosis-safe\.io$/,
      /^https:\/\/app\.gnosis-safe\.io$/,
      /^https:\/\/safe\.global$/,
      /^https:\/\/app\.safe\.global$/,
    ],
  });

  const wallets = [
    ledger,
    trezor,
    walletConnect,
    keepkey,
    walletLink,
    injected,
    fortmatic,
    portis,
    torus,
  ];

  const loadedInIframe = window.self !== window.top;

  init({
    wallets: loadedInIframe ? [gnosis] : wallets,
    chains: [
      {
        id: '0x' + Number(CHAIN_ID).toString(16),
        token: 'ETH',
        label: 'Ethereum Mainnet',
        rpcUrl,
      },
      {
        id: '0x' + Number(550055).toString(16),
        token: 'ETH',
        label: 'Tenderly TestNet',
        rpcUrl: TENDERLY_NETWORK_PROVIDER_HTTP_URL,
      },
    ],
    accountCenter: {
      desktop: { enabled: false },
    },
    appMetadata: {
      name: 'Nexus Mutual',
      icon: '<svg><svg/>',
      description: 'A decentralised alternative to insurance',
      recommendedInjectedWallets: [
        { name: 'MetaMask', url: 'https://metamask.io' },
        { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
      ],
    },
  });
};
