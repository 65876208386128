import React from 'react';

import GradientShimmer from 'Components/GradientShimmer';
import { Column, Row } from 'Components/gridComponents';
import ProductLogo from 'Components/ProductLogo';
import colors from 'Constants/colors';
import { Product } from 'Features/Membership/ducks/covers/initialState';
import { productTypesDisplay } from 'Services/productDetailsUtils';
import styled, { css } from 'styled-components';

const LogoContainer = styled.div`
  line-height: 0;
  & > * {
    margin-right: 1rem;
  }
`;

const ellipsisStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const HeadLg = styled.div`
  ${ellipsisStyle}
  font-size: 2rem;
`;

const Label = styled.div<{ typeLabel?: boolean }>`
  ${ellipsisStyle}
  color: ${({ typeLabel }) => (typeLabel && colors.greyAsher) || 'auto'};
  font-weight: ${({ typeLabel }) => (typeLabel && '400') || 'auto'};
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: ${({ typeLabel }) => (typeLabel && 'capitalize') || 'auto'};
`;

const ProductDetailsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  font-weight: 600;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

type ProjectLabelProps = {
  name: string;
  typeLabel?: boolean;
  loading?: boolean;
  size?: 'lg' | 'md';
};

const ProjectLabel = ({ typeLabel, size, name, loading }: ProjectLabelProps) => {
  if (size === 'md') {
    if (loading) {
      return <GradientShimmer width="75%" height="1rem" margin="0.25rem 0 0.25rem 0" />;
    }
    return <Label typeLabel={typeLabel}>{name}</Label>;
  }
  if (loading) {
    return <GradientShimmer width="75%" height="3rem" margin="0.5rem 0 0 0" />;
  }
  return <HeadLg>{name}</HeadLg>;
};

type ProductDetailsWrapperProps = {
  product: Product;
  size?: 'lg' | 'md';
  loading?: boolean;
};

const ProductDetailsWrapper = ({
  children,
  product,
  size = 'lg',
  loading,
}: React.PropsWithChildren<ProductDetailsWrapperProps>) => {
  const name = product?.name;
  const logo = product?.logo as string;
  const logoSize = (size === 'lg' && '4rem') || ((size === 'md' && '3rem') as string);
  return (
    <ProductDetailsContainer>
      <Row>
        <LogoContainer>
          {loading ? (
            <GradientShimmer width={logoSize} height={logoSize} />
          ) : (
            <ProductLogo src={logo} size={logoSize} name={name} />
          )}
        </LogoContainer>
        <Column overflow="hidden">
          <ProjectLabel loading={loading} name={name} size={size} />
          {size !== 'lg' ? (
            <ProjectLabel
              typeLabel
              loading={loading}
              name={productTypesDisplay[product?.type]}
              size={size}
            />
          ) : null}
        </Column>
      </Row>
      <Row>{children}</Row>
    </ProductDetailsContainer>
  );
};

export default ProductDetailsWrapper;
